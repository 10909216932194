import { useEffect, useState } from "react";
import axios from "axios";
import { CoursesPageData } from "../../types/";
import { PageServiceWrap, AdditionalWrap } from "./PageCourses.styled";
import Section from "../Section";
import HeroCourses from "../HeroCourses";
import CoursesCardList from "../CoursesCardList";
import CourseCreators from "../CourseCreators";
import CourseOpportunities from "../CourseOpportunities";

function PageCourses() {
  const [courses, setCourses] = useState<CoursesPageData | null>(null);

  useEffect(() => {
    const fetchData = async() => {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/products/get-courses`);
      if (response.data.courses.length > 0) {
        setCourses(response.data.courses[0]);
      }
    };  

    fetchData();
  }, []);

  return (
    <PageServiceWrap>
      <AdditionalWrap>
        <Section>
          {courses && <HeroCourses data={courses}></HeroCourses>}
        </Section>
        <Section marginB="0px">
          {courses && <CoursesCardList list={courses.courseCards} id="courses"/>}
        </Section>
          <Section marginB="120px">
            <CourseCreators />
          </Section>
        <Section marginB="120px">
          <CourseOpportunities />
        </Section>
      </AdditionalWrap>
    </PageServiceWrap>
  );
}

export default PageCourses;
