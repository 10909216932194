import { FC } from "react";
import {
  ReviewsWrap,
  ReviewsTitle,
  ReviewsMainInfo,
  ReviewsList,
  ReviewsRatingNumber,
  ReviewsNumber,
  ReviewsMoreButtonBlock,
  RatingAddittionalWrapper,
} from "./Reviews.styled";
import { TReviewsList } from "../../types";
import ReviewCard from "../ReviewCard";
import RatingStars from "../RatingStars";
import Button from "../Button";

interface ReviewsProps {
  title: string;
  reviews: TReviewsList;
}

const Reviews: FC<ReviewsProps> = ({ title, reviews }) => {
  const calculateAverageRating = (reviews: TReviewsList): number => {
    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    return totalRating / reviews.length;
  };

  const averageRating = calculateAverageRating(reviews);
  const ratingStarsWidth = window.innerWidth < 768 ? "34px" : "40px";
  return (
    <ReviewsWrap>
      <ReviewsTitle>
        Reviews <span>{title}</span>
      </ReviewsTitle>
      <ReviewsMainInfo>
        <ReviewsRatingNumber>{averageRating.toFixed(1)}</ReviewsRatingNumber>
        <RatingAddittionalWrapper>
          <RatingStars rating={averageRating} width={ratingStarsWidth} />
          <ReviewsNumber>{reviews.length} reviews</ReviewsNumber>
        </RatingAddittionalWrapper>
      </ReviewsMainInfo>
      <ReviewsList>
        {reviews.map((review, index) => (
          <ReviewCard key={index} review={review} />
        ))}
      </ReviewsList>
      {/*<ReviewsMoreButtonBlock>*/}
      {/*  <Button withIcon={false} text="Show More" width="172px" />*/}
      {/*</ReviewsMoreButtonBlock>*/}
    </ReviewsWrap>
  );
};

export default Reviews;
