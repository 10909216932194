import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import React from "react";
import sprite from "../../icons/symbol-defs.svg";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  CourseCreatorsWrapper,
  CourseCreatorsHeader,
  CourseCreatorsTitle,
  SliderNav,
  SliderNavButton,
} from "./CourseCreators.styled";
import { TCourseCreators, TCourseCreator } from "../../types/CourseCreator";
import CreatorCard from "../CreatorCard";
import NataliImage from "../../assets/image/creators/Natali.png";
import Brand1Image from "../../assets/image/brends/adidas.png";
import Brand2Image from "../../assets/image/brends/cola.png";
import Brand3Image from "../../assets/image/brends/tifany.png";
import Brand4Image from "../../assets/image/brends/adobe.png";

type CourseCardProps = {};

const CourseCreators: React.FC<CourseCardProps> = () => {
  const creators: TCourseCreators = [
    {
      id: 1,
      image: NataliImage,
      name: "Natalia Litus-Angelcheva",
      position: "CEO of Woohoo! Agency in the US and Emirates",
      linkendin:
        "https://www.linkedin.com/in/natalia-litus-angelcheva-a4b555256",
      achievement: [
        "Graduate of Carnegie Mellon University and Stanford University programs",
        "Led major advertising campaigns across the U.S., Emirates, and Europe",
        "Founder of Everbloom Technologies in Silicon Valley",
        "Marketing expert",
      ],
      brends: [Brand1Image, Brand2Image, Brand3Image, Brand4Image],
    },
  ];

  return (
    <CourseCreatorsWrapper>
      <CourseCreatorsHeader>
        <CourseCreatorsTitle>Course creators</CourseCreatorsTitle>
        <SliderNav>
          <SliderNavButton>
            <svg style={{ transform: "rotate(90deg)" }}>
              <use xlinkHref={`${sprite}#toggle`}></use>
            </svg>
          </SliderNavButton>
          <SliderNavButton>
            <svg style={{ transform: "rotate(-90deg)" }}>
              <use xlinkHref={`${sprite}#toggle`}></use>
            </svg>
          </SliderNavButton>
        </SliderNav>
      </CourseCreatorsHeader>
      <Swiper
        spaceBetween={40}
        slidesPerView={1}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper: any) => console.log(swiper)}
        style={{ overflow: "visible", width: "100%" }}
      >
        {creators.map((creator: TCourseCreator) => (
          <SwiperSlide key={creator.id}>
            <CreatorCard key={creator.id} data={creator} />
          </SwiperSlide>
        ))}
      </Swiper>
    </CourseCreatorsWrapper>
  );
};

export default CourseCreators;
