import React from "react";
import { TCourseCreator } from "../../types/index";
import sprite from "../../icons/symbol-defs.svg";
import {
  CardWrap,
  CardImageWrapper,
  CardImage,
  CardBody,
  CardHeader,
  CardInfo,
  CardTitle,
  CardPosition,
  CardLink,
  CardAchievements,
  CardAchievement,
  CardAchievementText,
  CardBrends,
  CardBrandsTitle,
  CardBrandsList,
  CardBrandsItem,
  Svg,
} from "./CreatorCard.styled";

type CreatorCardProps = {
  data: TCourseCreator;
};

const CreatorCard: React.FC<CreatorCardProps> = ({ data }) => {


  return (
    <CardWrap>
      <CardImageWrapper>
        <CardImage src={data.image} alt={data.name} />
      </CardImageWrapper>
      <CardBody>
        <CardHeader>
          <CardInfo>
            <CardTitle>{data.name}</CardTitle>
            <CardPosition>{data.position}</CardPosition>
          </CardInfo>
          <CardLink href={data.linkendin} target="_blank">
            <svg>
              <use xlinkHref={`${sprite}#linkedin`}></use>
            </svg>
            LinkedIn
          </CardLink>
        </CardHeader>
        <CardAchievements>
          {data.achievement.map((achievement: string, index: number) => (
            <CardAchievement key={index}>
              <Svg width="17px" height="23px">
                <use xlinkHref={`${sprite}#singl-star`}></use>
              </Svg>
              <CardAchievementText>{achievement}</CardAchievementText>
              </CardAchievement>
          ))}
        </CardAchievements>
        <CardBrends>
          <CardBrandsTitle>Collaborated with leading global brands</CardBrandsTitle>
          <CardBrandsList>
            {data.brends.map((brend: string, index: number) => (
              <CardBrandsItem key={index}>
                <img src={brend} alt="brand" />
              </CardBrandsItem>
            ))}
          </CardBrandsList>
        </CardBrends>
      </CardBody>
    </CardWrap>
  );
};

export default CreatorCard;
