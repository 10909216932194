import styled from "@emotion/styled";
import { buttonText, c2, c3, h5, violet, white } from "../../assets";
import bg from "../../assets/image/corses-detail-bg.png";
import smallBerry from "../../assets/image/shapes.png";

export const CourseDetailsWrapper = styled.div`
  display: flex;
  background-color: ${white};
  border-radius: 12px;
  background-image: url(${bg});
  padding: 48px 54px 0 54px;
  overflow: hidden;
  min-height: 455px;
  @media (max-width: 1240px) {
    flex-direction: column;
  }
`;
export const CourseDetailsDescription = styled.div`
  max-width: 400px;
  width: 100%;
  @media (max-width: 1240px) {
    max-width: 100%;
  }
`;
export const CourseDetailsContent = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const CourseDetailsTitle = styled.p`
  ${h5}
  margin-bottom: 30px;
`;
export const CourseDetailsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
`;
export const CourseDetailsItem = styled.li`
  max-width: 375px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  svg {
    min-width: 17px;
    min-height: 23px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
export const CourseDetailText = styled.p`
  ${c3}
`;
export const CourseDetailsImageWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  position: relative;
  @media (max-width: 768px) {
    max-width: 550px;
  }
  &:after {
    content: "";
    background-image: url(${smallBerry});
    position: absolute;
    bottom: -77px;
    right: -100px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 201px;
    height: 201px;
    @media (max-width: 1240px) {
      bottom: -20px;
      right: -100px;
    }
    @media (max-width: 1024px) {
      bottom: -40px;
      right: -100px;
    }
  }
`;
export const CourseDetailsImage = styled.img`
  width: 100%;
  transform: translateY(30px);
  @media (max-width: 1024px) {
    transform: translateX(50px);
  }
`;
export const CourseDetailsActionBlock = styled.div`
  max-width: 325px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
  @media (max-width: 1240px) {
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    margin-top: 31px;
    margin-bottom: 31px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const CourseDetailsActionButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  ${buttonText}
  height: 64px;
  width: 274px;
  border-radius: 12px;
  background-color: ${violet};
  text-align: center;
  border: none;
  cursor: pointer;
`;
export const CourseDetailsActionTitle = styled.p`
  ${c2}
  font-weight: 500;
  @media (max-width: 1240px) {
    max-width: 320px;
  }
`;
export const Svg = styled.svg`
  stroke: #16ea9e;
  fill: #16ea9e;
`;
