import styled from "@emotion/styled";
import { h2, h3, h6, yellow } from "../../assets";

export const CourseOpportunitiesWrapper = styled.div``;
export const Title = styled.h2`
  ${h2}
  display: flex;
  align-items: flex-start;
  gap: 10px;
  @media (max-width: 1024px) {
    ${h3}
  }
  @media (max-width: 768px) {
    ${h6}
    align-items: flex-end;
  }
`;

export const TitleWrap = styled.div`
  position: relative;
  margin-bottom: 32px;
  margin-right: 70px;
  max-width: 720px;
  width: 100%;
  @media (max-width: 1024px) {
    margin-bottom: 24px;
    margin-right: 70px;
  }
  @media (max-width: 768px) {
    margin-bottom: 32px;
    margin-right: 0px;
  }
`;

export const Svg = styled.svg`
  fill: ${yellow};
`;
export const CourseValues = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
  @media (max-width: 1140px) {
    grid-template-columns: 1fr;
  }
`;
