import React, { useState, useRef, useEffect, useCallback } from "react";
import { TCourseCard } from "../../types/index";
import sprite from "../../icons/symbol-defs.svg";
import {
  CardWrap,
  CardAdditionlWrapper,
  CardBody,
  TitleWrap,
  Title,
  Lessons,
  DescriptionText,
  Img,
  ReadMoreButton,
  Structure,
  StructureWrapper,
  SubstepWrapper,
  StructureTitle,
  StepWrapper,
  StepTitle,
  Substep,
  PriceBlock,
  OldOPrice,
  CurrentPrice,
  ImgWrap,
} from "./CourseCard.styled";
import Button from "../Button";

type CourseCardProps = {
  data: TCourseCard;
};

const CourseCard: React.FC<CourseCardProps> = ({ data,  }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [structureHeight, setStructureHeight] = useState<number | undefined>(undefined);
  const structureRef = useRef<HTMLDivElement>(null);

  const updateHeights = useCallback(() => {
    if (structureRef.current) {
      setStructureHeight(structureRef.current.scrollHeight);
    }
  }, []);

  useEffect(() => {
    updateHeights();
  }, [updateHeights]);

  useEffect(() => {
    const handleResize = () => updateHeights();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [updateHeights]);

  const handleClick = (link: string) => {
    window.open(link, "_blank", "noopener noreferrer");
  };

  const calculateDiscountedPrice = (originalPrice: number): number => {
    const discountRate = 0.1;
    return originalPrice * (1 - discountRate);
  };

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <CardWrap >
      <CardAdditionlWrapper isHovered={isExpanded} >
        <ImgWrap>
          <Img src={data.mainImg} alt="courses" />
        </ImgWrap>
        <CardBody>
          <TitleWrap>
            <Title>{data.title}</Title>
            <Lessons>{data.lessonsNumber}</Lessons>
          </TitleWrap>
          {data.description && (
            <DescriptionText>{data.description}</DescriptionText>
          )}
          <Structure
            isHovered={isExpanded}
            ref={structureRef}
            maxHeight={structureHeight}
          >
            <StructureTitle>Course Structure</StructureTitle>
            <StructureWrapper>
              {data.structure.map((step, index) => (
                <StepWrapper key={index}>
                  <StepTitle>{step.step}</StepTitle>
                  <SubstepWrapper>
                    {step.subStep.map((substep, subIndex) => (
                      <Substep key={subIndex}>{substep}</Substep>
                    ))}
                  </SubstepWrapper>
                </StepWrapper>
              ))}
            </StructureWrapper>
          </Structure>
          <div>
          <ReadMoreButton onClick={toggleExpand} open={isExpanded}>
            {isExpanded ? "Read less" : "Read more"}
            <svg>
              <use xlinkHref={`${sprite}#toggle`}></use>
            </svg>
          </ReadMoreButton>
            <PriceBlock>
              <CurrentPrice>
                ${calculateDiscountedPrice(data.price)}
              </CurrentPrice>
              <OldOPrice>${data.price}</OldOPrice>
            </PriceBlock>
            <Button onClick={() => handleClick(data.couseLink)} text="Proceed to Checkout" />
              </div>
        </CardBody>
      </CardAdditionlWrapper>
    </CardWrap>
  );
};

export default CourseCard;
