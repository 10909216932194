import styled from "@emotion/styled";
import {
  lightGray,
  white,
  inputText,
  footerTextT,
  footerTextM,
  companyAddres,
  yellow,
  c1,
  c2,
  c3,
  c4, d2,
} from '../../assets';
import { Link } from "react-router-dom";

export const FooterWrap = styled.footer`
  background-color: ${lightGray};
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 48px 64px;
  @media (max-width: 1024px) {
    padding: 32px;
  }
  @media (max-width: 768px) {
    padding: 40px 24px;
  }
`;

export const FooterCss = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

export const LogoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  width: 100%;
  @media (max-width: 768px) {
    margin-bottom: 34px;
  }
`;

export const Logo = styled.svg`
  fill: ${white};
  @media (max-width: 1024px) {
    width: 240px;
  }
  @media (max-width: 768px) {
    width: 327px;
  }
`;

export const CredoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${inputText}
  color:${white};
  gap: 8px;
  @media (max-width: 1024px) {
    ${footerTextT}
  }
  @media (max-width: 768px) {
    ${footerTextM}
    flex-direction: column;
    gap: 16px;
      
    opacity: .5;
  }
`;
export const MainInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 48px;
`;

export const FooterInfoTitle = styled.p`
  ${c1}
  color:#E35FCD;
  font-weight: 700;
  @media (max-width: 1024px) {
    ${c2}
  }
`;
export const FooterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const FooterBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 24px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    gap: 32px;
  }
`;

export const FooterAdressColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 400px;
`;
export const SocialMediaWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  @media (max-width: 768px) {
    align-items: center;
  }
`;
export const FooterCompanyAddres = styled.p`
  ${companyAddres}
  color:${white};
  & > span {
    font-weight: 800;
    color: ${yellow};
  }

  @media (max-width: 1024px) {
    ${c4}
  }
`;
export const SocialMediaSvgWrap = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: ${yellow};
  border-radius: 50%;
`;
export const SocialMediaSvg = styled.svg<{ type: string }>`
  ${(props) =>
    props.type === "instagram"
      ? `width: 48px;
  height: 48px;`
      : `width: 24px;
  height: 24px;`}
  stroke: #2b2f36;
  fill: #2b2f36;
`;

export const SocialMediaColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 45px;

  @media (max-width: 1024px) {
    gap: 24px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const SocialMediaList = styled.div`
  display: flex;
  gap: 16px;
`;
export const EmailWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  @media (max-width: 1024px) {
    display: none;
  }
`;
export const Email = styled.a`
  color: ${yellow};
  text-decoration: none;
  cursor: pointer;
  @media (max-width: 768px) {
    ${c3}
  }
`;
export const LinkWrap = styled.div`
  display: flex;
  gap: 6px;

  @media (max-width: 768px) {
    gap: 3px;
  }
`;
export const CustomLink = styled(Link)`
  color: ${white};
  text-decoration: none;
  cursor: pointer;
`;
