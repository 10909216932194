import styled from "@emotion/styled";
import { white, c3, c2, h6 } from "../../assets";
import CreatorBg from "../../assets/image/creator_bg.png";

export const CardWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 58px;
  background-color: ${white};
  border-radius: 36px;
  max-width: 1310px;
  width: 100%;
  height: 480px;
  @media (max-width: 1240px) {
    flex-direction: column;
    height: 920px;
    gap: 0px;
  }
  @media (max-width: 768px) {
    height: auto;
  }
  
`;
export const CardImageWrapper = styled.div`
  position: relative;
  background-image: url(${CreatorBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 36px;
  max-width: 440px;
  width: 100%;
  height: 100%;
  @media (max-width: 1240px) {
    max-width: 100%;
    height: 460px;
  }
`;

export const CardImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 40px;
  @media (max-width: 1240px) {
    margin: 32px;
  }
`;
export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CardTitle = styled.p`
  ${h6}
`;
export const CardPosition = styled.p`
  ${c3}
  color: #5E5E5E;
`;
export const CardLink = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;
  ${c3}
  text-decoration: underline;
  color: #333333;
  svg {
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    background-color: #0274b3;
    padding: 4px;
    use {
      stroke: ${white};
      fill: ${white};
    }
  }
`;
export const CardAchievements = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 24px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const CardAchievement = styled.div`
  max-width: 315px;
  display: flex;
  gap: 10px;
  svg {
    min-width: 17px;
    min-height: 23px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
export const CardAchievementText = styled.p`
  ${c3}
`;
export const CardBrends = styled.div`
  margin-top: 24px;
`;
export const CardBrandsTitle = styled.p`
  ${c2}
  font-weight: 500;
`;
export const CardBrandsList = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 16px;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
`;
export const CardBrandsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 170px;
  width: 100%;
  height: 66px;
  background-color: rgba(176, 184, 255, 0.19);
  @media (max-width: 1240px) {
    max-width: 100%;
  }
`;
export const Svg = styled.svg`
  stroke: #16EA9E;
  fill: #16EA9E;
`;
