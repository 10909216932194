import styled from "@emotion/styled";
import { buttonText, c2, c3, h5, white } from "../../assets";
import bg from "../../assets/image/card-value-bg.png";

export const CourseValueWrapper = styled.div<{ background: string }>`
  display: flex;
  flex-direction: column;
  background-color: ${white};
  border-radius: 12px;
  background-image: url(${bg});
  background-color: ${({ background }) => background};
  padding: 48px 54px 0 54px;
  overflow: hidden;
`;
export const CourseValueDescription = styled.div`
  max-width: 400px;
  width: 100%;
`;
export const CourseValueContent = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
`;
export const CourseValueTitle = styled.p`
  ${h5}
  margin-bottom: 24px;
`;
export const CourseValueList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 1140px) {
    max-width: 70%;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
export const CourseValueItem = styled.li`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  svg {
    min-width: 17px;
    min-height: 23px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
export const CourseDetailText = styled.p`
  ${c3}
`;
export const CourseValueImage = styled.img`
  width: 100%;
  height: 100%;
  transform: translateY(20px);
  margin-right: 0;
  @media (max-width: 1140px) {
    position: absolute;
    width: auto;
    height: auto;
    right: -20px;
  }
  @media (max-width: 768px) {
    position: relative;
    left: 0;
    transform: translateY(-40px);
  }
`;
export const CourseValueActionBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
`;
export const CourseValueActionButton = styled.button`
  all: unset;
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  ${buttonText}
  height: 64px;
  min-width: 274px;
  max-width: 274px;
  border-radius: 12px;
  background-color: ${white};
  text-align: center;
  border: none;
  margin-bottom: 54px;
  @media (max-width: 1140px) {
    position: relative;
    margin-top: 46px;
  }
  @media (max-width: 768px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
  }
`;
export const CourseValueSubtitle = styled.p`
  ${c2}
  font-weight: 500;
  margin-bottom: 30px;
`;
export const Svg = styled.svg`
  use {
    fill: #6a00b8;
  }
`;
