import styled from "@emotion/styled";
import { h3, lavender } from "../../assets";

type StructureProps = {
  isHovered: boolean;
  maxHeight?: number;
};

export const CourseCreatorsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const CourseCreatorsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 68px;
`;
export const CourseCreatorsTitle = styled.h2`
  ${h3}
`;
export const SliderNav = styled.div`
  display: flex;
  gap: 8px;
`;
export const SliderNavButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  border: none;
  svg {
    width: 24px;
    height: 24px;
    stroke: ${lavender};
  }
`;
