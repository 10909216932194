import styled from "@emotion/styled";
import { d2, h3, h6, titleM } from "../../assets";
import {d4} from '../../assets/typography';

export const HowWorkAiGeneratorTitle = styled.h3`
  margin-bottom: 16px;
  ${h3};
  @media (max-width: 1024px) {
    ${h3}
  }
  @media (max-width: 768px) {
    ${h6}
  }
`;
export const HowWorkAiGeneratorText = styled.p`
  margin-bottom: 64px;
  ${d2};
  @media (max-width: 1024px) {
    margin-bottom: 32px;
  }
  @media (max-width: 768px) {
    ${d4}
    margin-bottom: 24px;
  }
`;
