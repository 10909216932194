import React from "react";
import sprite from "../../icons/symbol-defs.svg";

import {
  CourseOpportunitiesWrapper,
  TitleWrap,
  Title,
  Svg,
  CourseValues,
} from "./CourseOpportunities.styled";
import CourseDetails from "../CourseDetails";
import CourseValue from "../CourseValue";
import achievmentImage from "../../assets/image/achievment.png";
import rocketImage from "../../assets/image/rocket.png";
import CourseForYou from "../CourseForYou";

type CourseOpportunitiesProps = {};

const CourseOpportunities: React.FC<CourseOpportunitiesProps> = () => {
  return (
    <CourseOpportunitiesWrapper>
      <TitleWrap>
        <Title>
          Your chance{" "}
          <Svg width="69" height="66">
            <use xlinkHref={`${sprite}#stars`}></use>
          </Svg>{" "}
        </Title>
        <Title>to change the game</Title>
      </TitleWrap>
      <CourseDetails />
      <CourseValues>
        <CourseValue
          img={rocketImage}
          values={[
            "The course will upgrade you as a next-level marketer",
            "It will add value to your profile as an expert",
          ]}
          title="Value"
          subtitle="After completing the course, you’ll know how to easily apply the knowledge to your own project"
          background="#DBE0FF"
        />
        <CourseForYou
          img={achievmentImage}
          values={[
            "Want to enhance your understanding of marketing trends",
            "Want to stay up-to-date with all the latest innovations in marketing",
            "Feel the desire to grow and develop your project according to new market dynamics",
            "Aim to master the profession of digital marketer and expand your knowledge in trending areas of marketing",
          ]}
          title="This course is for you if you"
          subtitle=""
          background="#E3FCF3"
        />
      </CourseValues>
    </CourseOpportunitiesWrapper>
  );
};

export default CourseOpportunities;
