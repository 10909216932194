import { useState, useEffect } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import PageLoading from "../components/PageLoading";

export const PrivateRoute = ({ component: Component, redirectTo }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userStatus, setUserStatus] = useState(null); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/user/get-user-info`,
          {},
          {
            withCredentials: true,
          }
        );

        if (response.data && typeof response.data.status === "string") {
          setUserStatus(response.data.status);
        } else {
          console.error(
            "Status is missing or is not a string in the response data"
          );
          setUserStatus(null);
        }
      } catch (error) {
        console.error("Error fetching user information:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <PageLoading />;
  }

  if (userStatus === "none") {
    return <Component />;
  }

  if (
    userStatus === "suspended" ||
    userStatus === "paid" ||
    userStatus === null
  ) {
    return <Navigate to="/payment-plans" />;
  }

  return <Navigate to={redirectTo} />;
};
