import styled from "@emotion/styled";
import {
  white,
  lightGray,
  c1,
  c3,
  black,
  structure,
  step,
  substep,
  c2,
  oldPriceText,
  purple,
} from "../../assets";

type StructureProps = {
  isHovered: boolean;
  maxHeight?: number;
};
type CardProps = {
  isHovered?: boolean;
  height?: number;
};

export const CardWrap = styled.div<CardProps>`
  background-color: ${white};
  border-radius: 12px;

  height: 100%;
  min-height: 770px;
  transition: height 0.2s linear;
  
`;
export const CardAdditionlWrapper = styled.div<CardProps>`
  background-color: ${white};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: height 0.2s linear;

  ${(props: { isHovered?: boolean }) =>
    props?.isHovered &&
    `
    z-index:3;
    margin-bottom: 24px;
    height: auto;

  `}
`;

export const CardBody = styled.div`
  padding: 32px 32px 0px 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Img = styled.img`
  width: 100%;
  height: 447px;
  border-radius: 12px 12px 0 0;
  background-color: ${lightGray};
  object-fit: cover;
  object-position: center;
  @media (max-width: 768px) {
    height: 349px;
  }
`;
export const ImgWrap = styled.div`
  width: 100%;
  height: 447px;
  border-radius: 12px 12px 0 0;
  background-color: ${lightGray};
  @media (max-width: 768px) {
    height: 349px;
  }
`;

export const DescriptionText = styled.p`
  ${c3}
  font-weight: 400;
  color: ${black};
  margin: 24px 0 0 0;
`;

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const Title = styled.p`
  ${c1}
  max-width: 380px;
  width: 100%;
  @media (max-width: 768px) {
    ${c2}
  }
`;

export const Lessons = styled.p`
  ${c3}
  text-align: right;
  font-weight: 400;
  width: 100px;
  padding-left: 15px;
  white-space: nowrap;
`;
export const ReadMoreButton = styled.button<{ open: boolean }>`
  all: unset;
  display: flex;
  align-items: center;
  gap: 8px;
  ${c3}
  color: ${purple};
  svg {
    width: 48px;
    height: 28px;
    stroke: ${purple};
    rotate: ${(props) => (props.open ? "180deg" : "0deg")};
    transition: rotate 0.15s ease-out;
  }
`;
export const Structure = styled.div<StructureProps>`
  max-height: 0;
  overflow: hidden;
  max-height: ${(props) => (props.isHovered ? `${props.maxHeight}px` : "0")};
  height: 100%;
  transition: max-height 0.15s ease-out;
`;

export const StructureTitle = styled.p`
  ${structure}
`;

export const StructureWrapper = styled.ol`
  padding-left: 25px;
`;

export const StepWrapper = styled.div``;

export const SubstepWrapper = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`;

export const StepTitle = styled.li`
  ${step}
`;

export const Substep = styled.li`
  ${substep}
`;

export const PriceBlock = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 24px;
  margin-top: 24px;
  color: #333333;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    gap: 8px;
  }
`;

export const CurrentPrice = styled.p`
  ${c1}
  font-weight: 600;
`;
export const OldOPrice = styled.p`
  ${oldPriceText}
  text-decoration: line-through;
`;
