import { useState, useEffect } from "react";
import axios from "axios";
import DashboardNavigation from "../DashboardNavigation";
import StyledPageWrapper from "../StyledPageWrapper";
import {
  PageDashboardWrap,
  PageDashboardAdditionalWrap,
} from "./PageDashboard.styled";
import DashboarContent from "../DashboardContent/DashboardContent";
import LoadingSpinner from "../LoadingSpinner";
import { useLocation } from "react-router-dom";
import { TUserSubscriptionInfo } from "../../types";
import { trackPurchase } from "../../utils/facebookPixel";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";

type Prices = {
  [key: string]: {
    monthly: number;
    yearly: number;
  };
};

function PageDashboar() {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string>("Dashboard");
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState<TUserSubscriptionInfo | null>(null);
  const transactions = useSelector(
    (state: RootState) => state.transactions.transactions
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/user/get-user-info`,
          {},
          {
            withCredentials: true,
          }
        );
        if (response && response.data && response.data.status) {
          setUserData(response.data);
        } else {
          setUserData(null);
        }
      } catch (error) {
        console.error("Error fetching user information:", error);
        setUserData(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const transactionIdParam = searchParams.get("transactionId");
    const existingTransaction = transactions.find(
      (transaction) => transaction.transactionId === transactionIdParam
    );

    if (transactionIdParam  && !existingTransaction) {
      const updatePlanPrices = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/payments/payment-plans`
          );
          const data = response.data;

          const newPrices = data.reduce((acc: Prices, item: any) => {
            const monthlyPrice =
              item.prices.find((price: any) => price.recurring === "month")
                ?.unitAmount || 0;
            const yearlyPrice =
              item.prices.find((price: any) => price.recurring === "year")
                ?.unitAmount || 0;

            acc[item.type] = {
              monthly: monthlyPrice / 100,
              yearly: yearlyPrice / 100,
            };
            return acc;
          }, {});
          if (userData?.subscriptionType && userData?.subscriptionPeriodType) {
            trackPurchase("", newPrices?.[userData.subscriptionType]?.[userData.subscriptionPeriodType] , "StartTrial");
          }
        } catch (error) {
          console.error("Error updating plan prices:", error);
        }
      };

      updatePlanPrices();

    }
  }, [location.search, userData, transactions]);

  if (isLoading) {
    return (
      <PageDashboardWrap>
        <LoadingSpinner
          width="300px"
          height="300px"
          $margint="100px"
          $marginb="100px;"
        />
        ;
      </PageDashboardWrap>
    );
  }

  return (
    <PageDashboardWrap>
      <StyledPageWrapper
        type="dashboard"
        backgroundColor="rgba(184, 191, 255, 0.15);"
      >
        <PageDashboardAdditionalWrap>
          <DashboardNavigation
            activeItem={activeItem}
            setActiveItem={setActiveItem}
          />
          <DashboarContent activeItem={activeItem} userInfo={userData} />
        </PageDashboardAdditionalWrap>
      </StyledPageWrapper>
    </PageDashboardWrap>
  );
}

export default PageDashboar;
