import React from "react";
import sprite from "../../icons/symbol-defs.svg";

import {
  CourseValueWrapper,
  CourseValueContent,
  CourseValueTitle,
  CourseValueList,
  CourseValueItem,
  CourseDetailText,
  CourseValueImage,
  CourseValueActionBlock,
  CourseValueActionButton,
  CourseValueSubtitle,
  Svg,
} from "./CourseForYou.styled";
type CourseValueProps = {
  img: string;
  values: string[];
  title: string;
  subtitle: string;
  background: string;
};

const CourseValue: React.FC<CourseValueProps> = ({
  img,
  values,
  title,
  subtitle,
  background,
}) => {

  const handleCourseDetailsButtonClick = () => {
    const element = document.getElementById("courses");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  return (
    <CourseValueWrapper background={background}>
      <CourseValueContent>
        <CourseValueTitle>{title}</CourseValueTitle>
        {subtitle && <CourseValueSubtitle>{subtitle}</CourseValueSubtitle>}
        <CourseValueList>
          {values.map((detail, index) => (
            <CourseValueItem key={index}>
              <Svg width="17px" height="23px" fill="none">
                <use xlinkHref={`${sprite}#check-green`}></use>
              </Svg>
              <CourseDetailText>{detail}</CourseDetailText>
            </CourseValueItem>
          ))}
        </CourseValueList>
      </CourseValueContent>
      <CourseValueActionBlock>
        <CourseValueActionButton onClick={handleCourseDetailsButtonClick}>
          <svg width="24px" height="24px">
            <use xlinkHref={`${sprite}#row-top`}></use>
          </svg>
          Choose the course
        </CourseValueActionButton>
        <CourseValueImage src={img} alt="course" />
      </CourseValueActionBlock>
    </CourseValueWrapper>
  );
};

export default CourseValue;
