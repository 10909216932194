import { InfoWrap, InfoText } from "./Banners.styled";
function InfoBanner() {
  return (
    <InfoWrap>
      <InfoText><span>Make it easy and save some</span> <b>🤑💸</b></InfoText>
    </InfoWrap>
  );
}

export default InfoBanner;
