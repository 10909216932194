import styled from "@emotion/styled";
import { d1, d3, h2, h3, h6, white, yellow } from "../../assets";
import { d5} from '../../assets/typography';

type TooltipWrapProps = {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
};
type TooltipIconProps = {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
};

export const TooltipWrap = styled.div<TooltipWrapProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 252px;
  height: 123.37px;
  padding: 24px 16px 24px 16px;
  background-color: ${white};
  border-radius: 20px;

  @media (min-width: 768px) and (max-width: 1300px) {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
  }
  @media (min-width: 240px) and (max-width: 767px) {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 88px;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 20px 22px;
      
  }
    
  ${(props) => props.top && `top: ${props.top};`}
  ${(props) => props.bottom && `bottom: ${props.bottom};`}
  ${(props) => props.left && `left: ${props.left};`}
  ${(props) => props.right && `right: ${props.right};`}
`;
export const TooltipIcon = styled.svg<TooltipIconProps>`
  width: 38px;
  height: 47px;
  ${(props) => props.fill && `fill: ${props.fill};`}

`;
export const TooltipText = styled.p`
  ${d1}
`;
export const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 123px;
  @media (max-width: 768px) {
    display: flex;
    margin-bottom: 72px;
  }
`;

export const Title = styled.h2`
  ${h2}
  text-align: center;
  @media (max-width: 1024px) {
    ${h2}
  }
  @media (max-width: 768px) {
    ${h6}
  }
`;

export const TitleWrap = styled.div`
  position: relative;
  margin-bottom: 20px;
  margin-right: 70px;
  @media (max-width: 1024px) {
    margin-bottom: 20px;
    margin-right: 0px;
  }
  @media (max-width: 768px) {
    margin-bottom: 32px;
    margin-right: 0px;
  }
`;

export const Svg = styled.svg`
  position: absolute;
  right: -75px;
  top: -35px;
  fill: ${yellow};
  transform: rotate(27deg);
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Description = styled.p`
  ${d3}
  margin-bottom: 35px;
  text-align: center;
  @media (max-width: 1024px) {
    width: 85%;
    margin: 24px 0 32px;
  }
  @media (max-width: 768px) {
    ${d5}
    margin-top: 0;
  }
`;

export const ImgWrap = styled.div`
  position: relative;

  @media (min-width: 768px) and (max-width: 1300px) {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
      
    & > :first-child {
      grid-column: span 3; /* Первый элемент занимает всю ширину (все 3 колонки) */
    }
  }
  @media (min-width: 240px) and (max-width: 767px) {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
`;

export const Img = styled.img`
  height: auto;
  margin-top: 14px;
  @media (max-width: 1024px) {
    right: -150px;
    top: 170px;
  }
  @media (max-width: 768px) {
    height: 110%;
    object-fit: cover;
    border-radius: 12px 12px 0 0;
  }
`;

export const HeroAiButtonWrap = styled.div`
    display: flex;

    @media (max-width: 767px) {
      display: none;
    }
`;

export const HeroAiButtonSecondWrap = styled.div`
    display: none;
    
    @media (max-width: 767px) {
      display: block;
    }
`;
