import styled from "@emotion/styled";
import { h3 } from "../../assets";
export const Wrap = styled.div`
  padding-bottom: 120px;

  @media (max-width: 1024px) {
    padding-bottom: 80px;
  }

  @media (max-width: 768px) {
    padding-bottom: 48px;
  }
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Title = styled.h2`
  ${h3}
  margin-bottom: 24px;
`;

export const ListElement = styled.div`
  background: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;
