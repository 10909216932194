import React from "react";
import { BannerWrap } from "./Banners.styled";
import SaleBanner from "./SaleBanner";
import TestBanner from "./TestBanner";
import InfoBanner from "./InfoBanner";
import InfoTimerBanner from "./InfoTimerBanner";
import CheckoutInformer from "./CheckoutInformer";

type BannerContainerProps = {
  background?: "green" | "white" | "blue";
  bannerType?: "sale" | "test" | "info" | "info-timer"| "checkout-informer";
  marginB?: string;
  mediaHeight?: string;
};

const BannerContainer: React.FC<BannerContainerProps> = ({
  background = "white",
  bannerType = "sale",
  marginB = "0",
  mediaHeight = ""
}) => {
  return (
    <BannerWrap
      background={background}
      bannerType={bannerType}
      marginB={marginB}
      mediaHeight={mediaHeight}
    >
      {bannerType === "sale" && <SaleBanner />}
      {bannerType === "test" && <TestBanner />}
      {bannerType === "info" && <InfoBanner />}
      {bannerType === "info-timer" && <InfoTimerBanner />}
      {bannerType === "checkout-informer" && <CheckoutInformer />}
    </BannerWrap>
  );
};

export default BannerContainer;
