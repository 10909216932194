import React from "react";
import { Wrap, Text, Svg } from "./ServcieIncItem.styled";
import sprite from "../../icons/symbol-defs.svg";

type ServiceIncItemProps = {
  text: string;
  full: boolean;
};

const ServiceIncItem: React.FC<ServiceIncItemProps> = ({ text, full }) => {
  return (
    <Wrap>
      <div>
        <Svg className="" width="40" height="40">
          <use xlinkHref={`${sprite}#check`}></use>
        </Svg>
        <Text full={full}>{text}</Text>
      </div>
    </Wrap>
  );
};

export default ServiceIncItem;
