export function trackPurchase(serviceName, amount, eventName="Purchase") {
  if (window.fbq) {
    window.fbq("track", eventName, {
      currency: "USD",
      value: amount,
      content_ids: serviceName,
    });
  } else {
    console.warn("Facebook Pixel не ініціалізовано");
  }
}
