import styled from "@emotion/styled";
import { Form } from "formik";
import {c1, c2, d2, d3, h5, h6, violet, white} from '../../assets';
import bigBerry from "../../assets/image/shapeb.png";
import smallBerry from "../../assets/image/shapes.png";

export const StyledForm = styled(Form)`
  padding-bottom: 120px;
  @media (max-width: 1024px) {
    padding-bottom: 48px;
  }
  @media (max-width: 768px) {
    padding-bottom: 34px;
  }
`;

export const Backdrop = styled.div`
  position: relative;
  @media (max-width: 1024px) {
    margin-bottom: 280px;
  }
  @media (max-width: 768px) {
    margin-bottom: 130px;
  }
`;

export const BackdropTitle = styled.p`
  ${h5}
  margin-bottom: 16px;
  @media (max-width: 1024px) {
    ${h6}
  }
  @media (max-width: 768px) {
    ${c1}
  }
`;

export const BackdropSubtitle = styled.p`
  ${d3}
  margin-bottom: 48px;
    
  @media (max-width: 1024px) {
    ${d2}
  }    
    
  @media (max-width: 768px) {
    ${d2}
  }
`;

export const FormWrap = styled.div`
  display: grid;
  grid-template-columns: 5fr 4fr;
  overflow: visible;
  position: relative;

  &:before {
    content: "";
    background-image: url(${bigBerry});
    position: absolute;
    top: 30px;
    right: -100px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 601px;
    height: 601px;
    @media (max-width: 1024px) {
      right: -30px;
      top: 270px;
      margin-bottom: 80px;
    }
    @media (max-width: 768px) {
      width: 322px;
      height: 322px;
      top: 320px;
      right: -60px;
    }
  }

  &:after {
    content: "";
    background-image: url(${smallBerry});
    position: absolute;
    top: -40px;
    right: 350px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 149px;
    height: 149px;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 768px) {
  }
`;

export const InputsWrap = styled.div`
  position: relative;
  padding: 40px 40px 64px 40px;
  display: flex;
  flex-direction: column;
  background-color: ${white};
  border-radius: 32px;
  @media (max-width: 1024px) {
    padding: 24px 32px 64px 32px;
  }
  @media (max-width: 768px) {
    padding: 16px 16px 24px 16px;
  }
`;

export const InputsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`;

export const FormTitle = styled.p`
  ${c1}
  text-align:center;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    ${c2}
  }
`;

export const TitleMod = styled.span`
  color: ${violet};
`;
