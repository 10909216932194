import styled from "styled-components";
import {c1, c2, d2, h3, h6, reviewText, titleM, violet} from '../../assets';
import {d4, faqTextMobile, reviewTextMobile} from '../../assets/typography';

export const AIGeneratorFAQWrap = styled.div``;
export const AIGeneratorFAQTitle = styled.h3`
  margin-bottom: 16px;
  ${h3};
  @media (max-width: 1024px) {
    ${h3}
  }
  @media (max-width: 768px) {
    ${h6}
    margin-top: 120px;
  }
`;
export const AIGeneratorFAQSubtitle = styled.p`
  margin-bottom: 16px;
  ${c2};

  @media (max-width: 1024px) {
    ${d2}
  }
  @media (max-width: 768px) {
    ${d4}
  }
`;
export const AIGeneratorFAQList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const AIGeneratorFAQItem = styled.div`
  border-radius: 30px;
  background-color: white;
  padding: 36px 48px;

  @media (max-width: 768px) {
    padding: 16px 24px;
      
    border-radius: 12px;
  }
`;
export const AIGeneratorFAQItemTitle = styled.p`
  ${c1}
  @media (max-width: 1024px) {
    font-weight: 500;
  }
  @media (max-width: 768px) {
    ${faqTextMobile}
  } 
`;
export const AIGeneratorFAQItemRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    align-items: center;
  }
`;
export const AIGeneratorFAQItemInfo = styled.div`
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;
export const AIGeneratorFAQItemToggleSvg = styled.svg`
  cursor: pointer;
  border-radius: 50%;
  background-color: ${violet};
  width: 32px;
  height: 32px;
  stroke: white;

  @media (max-width: 768px) {
    flex-basis: 32px;
    flex-shrink: 0;
  }
`;
export const AIGeneratorFAQItemDescription = styled.p`
  ${reviewText}
  margin-top: 20px;

    @media (max-width: 768px) {
        ${reviewTextMobile}
    }
`;
export const AIGeneratorFAQItemDescriptionList = styled.ul`
  margin-left: 30px;
`;
export const AIGeneratorFAQItemDescriptionItem = styled.li`
  ${reviewText}
  list-style-type: disc;
  span {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    ${reviewTextMobile}
  }
`;
