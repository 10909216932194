import React from "react";
import sprite from "../../icons/symbol-defs.svg";

import {
  CourseDetailsWrapper,
  CourseDetailsDescription,
  CourseDetailsContent,
  CourseDetailsTitle,
  CourseDetailsList,
  CourseDetailsItem,
  CourseDetailText,
  CourseDetailsImageWrapper,
  CourseDetailsImage,
  CourseDetailsActionBlock,
  CourseDetailsActionButton,
  CourseDetailsActionTitle,
  Svg,
} from "./CourseDetails.styled";
import certificateImage from "../../assets/image/certificate.png";
type CourseDetailsProps = {};

const CourseDetails: React.FC<CourseDetailsProps> = () => {
  const details = [
    "Engaging breakdown of key topics",
    "Analysis of the latest trending case studies",
    "Hands-on exercises",
    "Expert feedback and insights",
    "Interactive quizzes",
  ];
  const handleCourseDetailsButtonClick = () => {
    const element = document.getElementById("courses");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <CourseDetailsWrapper>
      <CourseDetailsContent>
        <CourseDetailsDescription>
          <CourseDetailsTitle>
            What to Expect from Our Courses
          </CourseDetailsTitle>
          <CourseDetailsList>
            {details.map((detail, index) => (
              <CourseDetailsItem key={index}>
                <Svg width="17px" height="23px">
                  <use xlinkHref={`${sprite}#singl-star`}></use>
                </Svg>
                <CourseDetailText>{detail}</CourseDetailText>
              </CourseDetailsItem>
            ))}
          </CourseDetailsList>
        </CourseDetailsDescription>
        <CourseDetailsImageWrapper>
          <CourseDetailsImage src={certificateImage} alt="course" />
        </CourseDetailsImageWrapper>
      </CourseDetailsContent>
      <CourseDetailsActionBlock>
        <CourseDetailsActionTitle>
          At the end, you’ll receive a personalized certificate of completion
        </CourseDetailsActionTitle>
        <CourseDetailsActionButton onClick={handleCourseDetailsButtonClick}>
          <svg width="24px" height="24px">
            <use xlinkHref={`${sprite}#row-top`}></use>
          </svg>
          Choose the course
        </CourseDetailsActionButton>
      </CourseDetailsActionBlock>
    </CourseDetailsWrapper>
  );
};

export default CourseDetails;
