import { useEffect, useState } from "react";
import { PageAiGeneratorWrap } from "./PageAiGenerator.styled";
import { TAIGeneratorCardList } from "../../types/";
import { aiGeneratorPageData } from "../../data";
import Section from "../Section";
import HeroAiGenerator from "../HeroAiGenerator";
import BannerContainer from "../Banners/BannerContainer";
import Form from "../OrderForm";
import AIGeneratorFeatures from "../AIGeneratorFeatures";
import HowWorkAiGenerator from "../HowWorkAiGenerator";
import AIGeneratorFAQ from "../AIGeneratorFAQ";
function PageAiGenerator() {
  const [features, setFeatures] = useState<TAIGeneratorCardList>([]);

  useEffect(() => {
    const fetchData = () => {
      if (aiGeneratorPageData.length > 0) {
        setFeatures(aiGeneratorPageData);
      }
    };

    fetchData();
  }, []);

  return (
    <PageAiGeneratorWrap>
      <Section>
        <HeroAiGenerator></HeroAiGenerator>
      </Section>
      <Section>
        <AIGeneratorFeatures list={features} />
      </Section>
      <Section>
        <HowWorkAiGenerator />
      </Section>
      <Section>
        <BannerContainer bannerType="info" background={"white"} mediaHeight="100%"/>
      </Section>
      <Section>
        <AIGeneratorFAQ />
      </Section>
      <Section overflow="hidden" marginB="0">
        <Form />
      </Section>
    </PageAiGeneratorWrap>
  );
}

export default PageAiGenerator;
