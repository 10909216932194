import React from "react";
import { TCoursesCardList } from "../../types";
import { Wrap, List, Title, Column, ListElement } from "./CoursesCardList.styled";
import BannerContainer from "../Banners/BannerContainer";
import CourseCard from "../CourseCard";

type CoursesCardListProps = {
  list: TCoursesCardList;
  id: string;
};

const CoursesCardList: React.FC<CoursesCardListProps> = ({ list, id }) => {
  const evenCards = list.filter((card) => card.id % 2 === 0); 
  const oddCards = list.filter((card) => card.id % 2 !== 0); 

  return (
    <Wrap id={id}>
      <Title>Our Signature Courses</Title>
      <BannerContainer bannerType="sale" background={"white"} marginB="64px" />
      <List>
        <Column>
          {oddCards.map((card) => (
            <ListElement key={card.id}>
              <CourseCard
                key={card.id}
                data={card}
              />
            </ListElement>
          ))}
        </Column>
        <Column>
          {evenCards.map((card) => (
            <ListElement key={card.id}>
              <CourseCard
                key={card.id}
                data={card}
              />
            </ListElement>
          ))}
        </Column>
      </List>
    </Wrap>
  );
};

export default CoursesCardList;
