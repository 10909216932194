import React from "react";
import { TAIGeneratorCardList } from "../../types";
import sprite from "../../icons/symbol-defs.svg";
import {
  FeaturesWrap,
  FeaturesTitleWrap,
  FeaturesTitle,
  FeaturesList,
  ListElement,
  Svg,
} from "./AIGeneratorFeatures.styled";
import AIGeneratorFeaturesCard from "../AIGeneratorFeaturesCard";

type AIGeneratorFeaturesProps = {
  list: TAIGeneratorCardList;
  marginB?: string;
};

const AIGeneratorFeatures: React.FC<AIGeneratorFeaturesProps> = ({
  list,
  marginB = "0",
}) => {
  return (
    <FeaturesWrap>
      <FeaturesTitleWrap>
        <Svg width="69" height="66">
          <use xlinkHref={`${sprite}#stars`}></use>
        </Svg>
        <FeaturesTitle>
          Generate a ready to use content plan for your social networks and
          marketing strategy for your brand in <span>just one minute</span>
        </FeaturesTitle>
      </FeaturesTitleWrap>
      <FeaturesList marginB={marginB}>
        {list.map(({ text, id, img, link }) => {
          return (
            <ListElement key={id}>
              <AIGeneratorFeaturesCard
                img={img}
                text={text}
                id={id}
                link={link}
              />
            </ListElement>
          );
        })}
      </FeaturesList>
    </FeaturesWrap>
  );
};

export default AIGeneratorFeatures;
